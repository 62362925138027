import React, { useState, useContext } from 'react';
//import './customers.css';
import Title from'../../components/Title';
import Header from'../../components/Header';
import firebase from '../../services/firebaseConnection';
import {  FiEdit } from 'react-icons/fi';

import { AuthContext } from '../../contexts/auth';
import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

export default function NewCategory() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  let uid = user.uid;

  const [nomeCategoria, setNomeCategoria] = useState('');

  
  async function handleAdd(e){
    e.preventDefault();

    if(nomeCategoria !== '' ){
      await firebase.firestore().collection('pdvUsers')
      .doc(uid)
      .collection('categorias')
      .add({
        nomeCategoria: nomeCategoria,
        created: new Date(),
      })
      .then(() =>{
        setNomeCategoria('');
        toast.info('Categoria cadastrada com sucesso!');
        navigate('/categories');
      })
      .catch((err) =>{
        console.log(err);
        toast.error('Erro ao cadastrar categoria')
      })
    }else{
      toast.error('Preencha todos os campos!');
    }

  }


 return (
   <div>
      <Header/>

      <div className="content">
        <Title name="Cadastrar categoria">
          <FiEdit size={25}/>
        </Title>
      

        <div className="container" > 
            <form className="form-profile customers" onSubmit={handleAdd}>
                <label>Nome da categoria</label>
                <input type="text" value={nomeCategoria} placeholder="Nome da categoria a ser criada" onChange={ (e) => setNomeCategoria(e.target.value)}/>
           
                <button type="submit">Cadastrar categoria</button>
            </form>
        </div>
      </div>
   </div>
 );
}