// import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import  'firebase/compat/database'

import { getAuth } from "firebase/auth";
import {initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'
import {getDatabase} from 'firebase/database'


let firebaseConfig = {
  // apiKey: "AIzaSyCBk8foW-ww0g0Fm9jiQPiFaXM0dG8lGNs",
  // authDomain: "leeats-production.firebaseapp.com",
  // projectId: "leeats-production",
  // storageBucket: "leeats-production.appspot.com",
  // messagingSenderId: "964095951102",
  // appId: "1:964095951102:web:8180db0b53dae5306d65af",
  // measurementId: "G-QWMF520RV6"
  apiKey: "AIzaSyDln8lyvPo03qKT3WK-GgnCOrSlrgtiYkA",
  authDomain: "leeatswebandappproduction.firebaseapp.com",
  databaseURL: "https://leeatswebandappproduction-default-rtdb.firebaseio.com",
  projectId: "leeatswebandappproduction",
  storageBucket: "leeatswebandappproduction.appspot.com",
  messagingSenderId: "1066530833538",
  appId: "1:1066530833538:web:56b63fba9f2b3ba077a09b",
  measurementId: "G-45YSFLCZ8X"
  
};

// if(!firebase.apps.length){
//   // Initialize Firebase  
//   firebase.initializeApp(firebaseConfig);
// }

const app = initializeApp(firebaseConfig);


const firebaseFirestore = getFirestore(app)

const firebaseDatabase = getDatabase(app)

const firebaseAuth = getAuth(app);

export {firebaseFirestore, firebaseDatabase, firebaseAuth };
