
import '../Dashboard/dashboard.css';
import './categories.css'
import { useState, useEffect, useContext } from 'react'

import Header from '../../components/Header';
import Title from '../../components/Title';
import { FiMessageSquare, FiPlus, FiSearch, FiEdit2, FiList, FiTrash2 } from 'react-icons/fi';
import { BiListPlus } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { AuthContext } from '../../contexts/auth';
import firebase from '../../services/firebaseConnection';
import Modal from '../../components/Modal';
import { toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


export default function Categories() {
  const { user } = useContext(AuthContext);
  let uid = user.uid;

  const listRef = firebase.firestore().collection('pdvUsers').doc(uid).collection('categorias').orderBy('created', 'desc');

  const [categorias, setCategorias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [lastDocs, setLastDocs] = useState();

  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();

  useEffect(() => {
    async function loadCategorias() {
      await listRef.limit(10)
        .get()
        .then((snapshot) => {
          updateState(snapshot)
        })
        .catch((err) => {
          console.log('Erro ao buscar: ', err);
          setLoadingMore(false);
        })

      setLoading(false);
    }

    loadCategorias();

    return () => {

    }

  }, []);



  async function updateState(snapshot) {
    const isCollectionEmpty = snapshot.size === 0;

    if (!isCollectionEmpty) {
      let lista = [];

      snapshot.forEach((doc) => {
        lista.push({
          id: doc.id,
          nomeCategoria: doc.data().nomeCategoria,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy'),

        })
      })

      const lastDoc = snapshot.docs[snapshot.docs.length - 1];//pegando o ultimo documento buscado

      setCategorias(categorias => [...categorias, ...lista])
      setLastDocs(lastDoc);

    } else {
      setIsEmpty(true);
    }

    setLoadingMore(false);
  }

  async function handleMore() {
    setLoadingMore(true);
    await listRef.startAfter(lastDocs).limit(10)
      .get()
      .then((snapshot) => {
        updateState(snapshot)
      })

  }

  async function handleDelete(idCat) {
     confirmAlert({
       title: 'Deseja excluir categoria?',
       message: 'uma vez feito isso, não será possivel reverter.',
       buttons: [
         {
           label: 'Sim',
           onClick: () => firebase.firestore().collection('pdvUsers').doc(uid).collection('categorias').doc(idCat).delete().then(() => {
            toast.success('Categoria excluida, atualize a página!');
            console.log("Document successfully deleted!");
             
           }).catch((error) => {
            toast.error('Erro ao excluir categoria.')
             console.error("Error removing document: ", error);
           })
         },
         {
           label: 'Não',
           onClick: () => ({})
         }
       ]
     });

  }

  function togglePostModal(item) {
    setShowPostModal(!showPostModal) // trocando de true para false
    setDetail(item);

  }


  if (loading) {
    return (
      <div>
        <Header />

        <div className="content">
          <Title name="Categorias">
            <FiMessageSquare size={25} />
          </Title>

          <div className="container dashboard">
            <span>Buscando categorias e produtos...</span>
          </div>

        </div>
      </div>
    )
  }


  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Categorias">
          <BiListPlus size={25} />
        </Title>

        {categorias.length === 0 ? (

          <div className="container dashboard">
            <span>Nenhuma categoria ou produto registrado...</span>

            <Link to="/newcategory" className="new">
              <FiPlus size={25} color="#fff" />
              Nova Categoria
            </Link>

            <Link to="/newproduct" className="new">
              <FiPlus size={25} color="#fff" />
              Novo produto
            </Link>

          </div>

        ) : (
          <>
            <Link to="/newcategory" className="new">
              <FiPlus size={25} color="#fff" />
              Nova Categoria
            </Link>

            <Link to="/newproduct" className="new">
              <FiPlus size={25} color="#fff" />
              Novo produto
            </Link>

            <table>
              <thead>
                <tr>
                  <th scope="col">Categoria</th>
                  {/* <th scope="col">Total de produtos</th> */}
                  <th scope="col">Ultima autalização em</th>
                  <th scope="col">#</th>
                  <th scope="col">#</th>
                </tr>
              </thead>
              <tbody>
                {categorias.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label="Categoria">{item.nomeCategoria}</td>
                      {/* <td data-label="Total">xx</td> */}
                      <td data-label="Atualizacao">{item.createdFormated}</td>
                      <td data-label="#">
                        <Link className="action" style={{ backgroundColor: '#f6a935' }} to={`/categories/${item.id}`}>
                          <FiList color="#fff" size={17} />
                        </Link>

                      </td>
                      <td data-label="#"><button className="btn-del" onClick={() => handleDelete(item.id)}> <FiTrash2 color="#f00" size={17} /></button>{ }</td>
                    </tr>
                  )
                })}


              </tbody>
            </table>


            {loadingMore && <h3 style={{ textAlign: 'center', marginTop: 15 }} >Buscando dados...</h3>}
            {!loadingMore && !isEmpty && <button className="btn-more" onClick={handleMore}>Buscar mais</button>}

          </>
        )}

      </div>

      {showPostModal && (
        <Modal
          conteudo={detail}
          close={togglePostModal}
        />
      )}

    </div>
  );
}