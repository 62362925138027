import React, { useState } from "react";
import "./modal.css";
import { format, toDate } from "date-fns";
import { FiX } from "react-icons/fi";

import { GrChapterNext } from "react-icons/gr";
import { GiReceiveMoney } from "react-icons/gi";
import { TbPlayerTrackNextFilled } from "react-icons/tb";

export default function Modal({
  data,
  close,
  nextStatus,
  accept,
  confirmReceipt,
}) {
  return (
    <div className="modal-details-order">
      <div className="container">
        <button className="close-modal-details-order" onClick={close}>
          <FiX size={23} color="#fff" />
          Voltar
        </button>
        <div styles={{ overflowY: "scroll" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "25px",
            }}
          >
            <h2>Detalhes do pedido:</h2>
            {data?.status === "pending" ? (
              <div>
                <span>
                  Status:{" "}
                  <i
                    style={{
                      color: "#fff",
                      backgroundColor:
                        data?.status === "pending" ? "#5cb85c" : "#999",
                      padding: 10,
                      borderRadius: 4,
                    }}
                  >
                    {data?.status === "pending" ? "Verificando" : data?.status}
                  </i>
                </span>
                <div
                  style={{
                    position: "fixed",
                    top: "5%",
                    right: "5%",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "rgba(255,0,0,0.6)",
                      color: "#FFF",
                      border: 1,
                      margin: "4px",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "5px 15px",
                      borderRadius: "5px",
                    }}
                    onClick={() =>
                      console.log(
                        data?.status,
                        data?.idRequest,
                        data?.uidUserRequest
                      )
                    }
                  >
                    Recusar
                  </button>

                  <button
                    style={{
                      backgroundColor: "rgba(0,255,0,0.5)",
                      color: "#fff",
                      border: 1,
                      margin: "4px",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "5px 55px",
                      borderRadius: "5px",
                    }}
                    onClick={() => accept(data) && close()}
                  >
                    Aceitar
                  </button>
                </div>
              </div>
            ) : (
              <div style={{ justifyContent: "center" }}>
                <span>
                  Status:{" "}
                  <i
                    style={{
                      backgroundColor:
                        data.status === "pending"
                          ? "#ff0"
                          : data.status === ("finished" || "delivered")
                          ? "#5cb85c"
                          : data.status === "cancelled"
                          ? "#f00f"
                          : "#e58711",
                      color: "#000",
                      padding: 10,
                      borderRadius: 4,
                      fontWeight: "normal",
                    }}
                  >
                    {data?.status === "pending"
                      ? "Verificando"
                      : data?.status === "accepted"
                      ? "Aceito"
                      : data?.status === "in_production"
                      ? "Em produção"
                      : data?.status === "delivered"
                      ? "Entregue"
                      : data?.status === "finished"
                      ? "Finalizado"
                      : data?.status === "cancelled"
                      ? "Cancelado"
                      : data?.status || ""}
                  </i>
                </span>

                {data?.status === "finished" ? null : (
                  <button
                    style={{
                      backgroundColor: "var(--primary-default)",
                      color: "#fff",
                      border: 1,
                      margin: "4px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 5,

                      padding: "5px",
                      gap: "5px",
                      position: "fixed",
                      top: "5%",
                      right: "5%",
                    }}
                    onClick={() => nextStatus(data) && close()}
                  >
                    AVANÇAR STATUS
                    <TbPlayerTrackNextFilled color="#fff" size={15} />
                  </button>
                )}
              </div>
            )}
          </div>

          <div className="row">
            <span>
              Cliente: <i>{data?.customer_name}</i>
            </span>
            <div id="line-modal-details-order" />
            <p />
            <span>
              Mesa: <i>{data?.table?.identification}</i>
              (situação da mesa: {data?.table?.occupants.length}/
              {data?.table?.seats})
            </span>
            <div id="line-modal-details-order" />

            <span style={{}}>Local de pagamento:</span>
            <i>
              {data?.payment.place === "online"
                ? "Aplicativo / Online"
                : data?.payment.place === "local"
                ? "Local"
                : "null"}
            </i>

            <span style={{ marginLeft: 20 }}>Metodo de pagamento: </span>
            <i>
              {data?.payment?.method === "credit_card"
                ? "Cartão de crédito"
                : data?.payment?.method === "money"
                ? "Dinheiro"
                : data?.payment?.method === "pix"
                ? "Pix"
                : "null"}
            </i>
            <div id="line-modal-details-order" />

            <div>
              <span>Valor total: </span>
              <i>R$ {parseFloat(data?.payment?.amount).toFixed(2)}</i>

              <span style={{ marginLeft: 20 }}>Troco para: R$</span>
              <i>
                {isNaN(data?.payment.change_for_payment)
                  ? "Não informado"
                  : parseFloat(data?.payment.change_for_payment).toFixed(2)}
              </i>
              {data?.payment.change_for_payment <
              data?.payment.amount ? null : (
                <>
                  <span style={{ marginLeft: 20 }}>Troco sugerido: </span>
                  <i>
                    R${" "}
                    {parseFloat(
                      data?.payment.change_for_payment - data?.payment.amount
                    ).toFixed(2)}{" "}
                  </i>
                </>
              )}
            </div>
            <div id="line-modal-details-order" />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <span>Status recebimento:</span>
                <i>
                {data?.payment?.status === "pending"
                        ? "Pendente"
                        : data?.payment?.status === "paid"
                        ? "Pago"
                        : data?.payment?.status === "partially_paid"
                        ? "Pago parcialmente"
                        : data?.payment?.status === "not_paid"
                        ? "Não pago"
                        : data?.payment?.status || ""}
                </i>

                <span style={{ marginLeft: 20 }}>Valor Recebido:</span>
                <i>
                  R$ {parseFloat(data?.payment?.received_amount).toFixed(2)}
                </i>
              </div>
              {data?.payment?.status !== "paid" ? (
                <button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                    justifyContent: "center",
                    backgroundColor: "var(--primary-default)",
                    border: 0,
                    borderRadius: 5,
                    color: "#fff",
                    padding: 5,
                  }}
                  onClick={() => confirmReceipt(data)}
                >
                  <span style={{ color: "#fff" }}>
                    Confirmar recebimento total
                  </span>
                  <GiReceiveMoney color="#fff" size={20} />
                </button>
              ) : null}
            </div>
            <div id="line-modal-details-order" />
            <p />

            {/* {
              status === 'Agendamento' ? 'Solicitar agendamento'
                : status === 'Progresso' ? 'Em progresso'
                  : status === 'Atendido' ? 'Atendido'
                    : 'error'} */}

            <span>
              Realizado em:
              {/* <i>{format(toDate(data?.created), "dd/MM/yyyy - HH:mm:ss")}</i> */}
              {format(
                toDate(new Date(data?.created_at)),
                "dd/MM/yyyy - HH:mm:s"
              )}
            </span>
            <div id="line-modal-details-order" />
            <span>
              Finalizado em:
              {data?.status === "finished"
                ? format(
                    toDate(new Date(data?.finished_at)),
                    "dd/MM/yyyy - HH:mm:s"
                  )
                : ' Não finalizado'}
            </span>
            <i></i>
            <p />
          </div>
          <div className="row"></div>
          {/* <Section>
                    <Label>Realizado em:</Label><TextDetail>{format(toDate(data?.created), 'dd/MM/yyyy - HH:mm:ss')}</TextDetail> 
                  </Section>
                  <Line />
                  <Section>
                    <Label>Finalizado em:</Label><TextDetail>{format(toDate(data?.finished), 'dd/MM/yyyy - HH:mm:ss')}</TextDetail> 
                  </Section> */}
          <div className="row"></div>

          <div id="status-modal"></div>

          <h3>
            Lista de itens: ({data?.list.length} -{" "}
            {data?.list.length > 1 ? "itens" : "item"})
          </h3>

          <table id="table" className="table-list-items-order">
            <thead>
              <tr style={{ border: 2 }}>
                <th
                  style={{ width: "35px", backgroundColor: "#777" }}
                  scope="col"
                >
                  #
                </th>
                <th
                  style={{ width: "35px", backgroundColor: "#ccc" }}
                  scope="col"
                >
                  QTD
                </th>
                <th
                  style={{ width: "45px", backgroundColor: "#ccc" }}
                  scope="col"
                >
                  UN
                </th>
                <th
                  style={{ width: "55px", backgroundColor: "#ccc" }}
                  scope="col"
                >
                  V.UN
                </th>
                <th
                  style={{ width: "75px", backgroundColor: "#ccc" }}
                  scope="col"
                >
                  V. Total
                </th>
                <th
                  style={{
                    textAlign: "left",
                    width: "350px",
                    backgroundColor: "#eee",
                  }}
                  scope="col"
                >
                  Produto
                </th>
                <th
                  style={{
                    textAlign: "left",
                    width: "200px",
                    backgroundColor: "#ccc",
                  }}
                  scope="col"
                >
                  Imagem Produto
                </th>
                <th
                  style={{
                    textAlign: "left",
                    backgroundColor: "#eee",
                  }}
                  scope="col"
                >
                  Observação do cliente
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.list.map((item, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td style={{ backgroundColor: "#777" }} data-label="#">
                        {index + 1}º
                      </td>
                      <td style={{ backgroundColor: "#ccc" }} data-label="QTD">
                        {item.quantity}
                      </td>
                      <td style={{ backgroundColor: "#eee" }} data-label="UN">
                        {item.unit_type}
                      </td>
                      <td
                        style={{ backgroundColor: "#ccc" }}
                        data-label="V. UN"
                      >
                        {(item.amount)/100}
                      </td>
                      <td
                        style={{ backgroundColor: "#eee" }}
                        data-label="V. TOTAL"
                      >
                        {((item.amount * item.quantity)/100).toFixed(2)}
                      </td>
                      <td
                        style={{
                          textAlign: "left",
                          backgroundColor: "#ccc",
                        }}
                        data-label="Produto"
                      >
                        {item.product_name}
                      </td>
                      <td
                        style={{
                          textAlign: "left",
                          width: "200px",
                          backgroundColor: "#eee",
                        }}
                        data-label="Imagem Produto"
                      >
                        <img
                          className="imageUrl-item-request"
                          src={item.image_url}
                          alt="Foto produto"
                        />
                      </td>
                      <td
                        style={{
                          textAlign: "left",

                          backgroundColor: "#ccc",
                        }}
                        data-label="Observação do cliente"
                      >
                        {item.note}
                      </td>
                    </tr>
                    <tr>
                      <div style={{ backgroundColor: "#000", height: 1 }}></div>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
