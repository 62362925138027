import config from "../../config/index.json"

export async function createTable(user, data) {
  let url = `${config.urlServer}/pdv/tables/create`;
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    uid: user.uid,
    tokenfirebase: user?.token_firebase,
    tokenbackend: user?.token_backend,
    email: user.email,
    restaurantid: user.restaurant_id,
    userpdvid: user.user_pdv_id
  };


  try {
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });

    if (response.status === 201 || response.status === 200) {
      const data = await response.json();
      console.log(data);
      return data;
    } else {
      const data = await response.json();
      console.log("Erro na requisição:", response.status, data.msg)}
  } catch (err) {
    console.error("Erro na requisição:", err);
  }
  // Retorno padrão em caso de erro ou condição não satisfeita
  return null;
}
