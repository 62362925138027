
import '../../Dashboard/dashboard.css';
import './historyrequest.css';
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../../contexts/auth';

import Header from '../../../components/Header';
import Title from '../../../components/Title';
import { FiMessageSquare, FiPlus, FiSearch, FiEdit2 } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';
import { GrHistory } from "react-icons/gr";
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { MdRestaurantMenu } from 'react-icons/md';
import firebase from '../../../services/firebaseConnection';
import Modal from '../../../components/Modal';

import { useNavigate, useParams } from 'react-router-dom';


export default function HistoryRequestYear() {
  const navigate = useNavigate();

  const year = 'year2022';



  const { user, deleteRequest } = useContext(AuthContext);

  const [requests, setRequests] = useState([]);

  const [historyRequestYear, setHistoryRequestYear] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();
  const listRef = firebase.firestore().collection('pdvUsers').doc(user.uid).collection('requestsPrevious')//.doc('year2021').collection('month12');
  const [monthSelected, setMonthSelected] = useState('month01');

  function handleChangeMonth(e) {
    setMonthSelected(e.target.value);
  }

  useEffect(() => {

    async function loadHistoryYear() {
      await listRef.get()
        .then((snapshot) => {
          let list = [];

          snapshot.forEach((doc) => {
            list.push({
              idYear: doc.id,
            })
          })
          setHistoryRequestYear(list);
          console.log(list)
          setLoading(false);

        })
        .catch(() => {
          console.log('DEU ALGUM ERRO!');
        })
    }
    loadHistoryYear();
  }, [])
  
  function liberarAno() {
    console.log(user.uid)
    firebase.firestore()
      .collection('pdvUsers')
      .doc(user.uid)
      .collection('requestsPrevious').doc('year2022').set({ year: 'year2022' })
  }


  if (loading) {
    return (
      <div>
        <Header />

        <div className="content">
          <Title name="Histórico de pedidos | Anos">
            <FiMessageSquare size={25} />
          </Title>

          <div className="container dashboard">
            <span>Buscando pedidos...</span>
          </div>

        </div>
      </div>
    )
  }


  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Histórico de pedidos | Anos">
          <MdRestaurantMenu size={25} />
        </Title>

        {historyRequestYear.length === 0 ? (

          <div className="container dashboard">
            <span>Nenhum pedido registrado no histórico...</span>
            <span>Caso já tenhas arquivado algum pedido e ainda não está aparecendo em seu histórico <button style={{ border: 0 }}
              onClick={() => liberarAno()}> clique aqui</button> e depois atualize a página.</span>
          </div>

        ) : (
          <>

            <table>
              <thead>
                <tr>
                  <th scope="col">Ano</th>
                  <th scope="col">Mês</th>
                  <th scope="col">#</th>
                </tr>
              </thead>
              <tbody>
                {historyRequestYear.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label="Ano">{item.idYear}</td>
                      <td data-label="Mes">
                        <select name="months" id="months" value={monthSelected} onChange={handleChangeMonth}>
                          <option value={'month01'}>Janeiro</option>
                          <option value={'month02'}>Fevereiro</option>
                          <option value={'month03'}>Março</option>
                          <option value={'month04'}>Abril</option>
                          <option value={'month05'}>Maio</option>
                          <option value={'month06'}>Junho</option>
                          <option value={'month07'}>Julho</option>
                          <option value={'month08'}>Agosto</option>
                          <option value={'month09'}>Setembro</option>
                          <option value={'month10'}>Outubro</option>
                          <option value={'month11'}>Novembro</option>
                          <option value={'month12'}>Dezembro</option>
                        </select>
                      </td>
                      <td>
                        <button className="toFile" style={{ backgroundColor: 'rgba(0,255,1, .9)' }}
                          onClick={() => navigate(`/historyrequestyear/${item.idYear}/${monthSelected}`)}>
                          <em color="#fff">Detalhes</em>
                        </button>
                      </td>

                    </tr>
                  )
                })}


              </tbody>
            </table>

          </>
        )}

      </div>



    </div>
  );
}