
import '../Dashboard/dashboard.css';
import './products.css';
import { useState, useEffect, useContext } from 'react'

import Header from '../../components/Header';
import Title from '../../components/Title';
import { FiMessageSquare, FiPlus, FiSearch, FiEdit2 } from 'react-icons/fi';
import { CgListTree } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';

import { AuthContext } from '../../contexts/auth';
import firebase from '../../services/firebaseConnection';
import Modal from '../../components/Modal';


export default function Products() {
  
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  let uid = user.uid;

  const listRef = firebase.firestore().collection('pdvUsers').doc(uid).collection('categorias');

  const [produtos, setProdutos] = useState([]);
  const [categoria, setCategoria] = useState('Produtos');
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [lastDocs, setLastDocs] = useState();

  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();

  useEffect(()=>{
    async function loadCategorias() {

      await listRef.doc(id).get().then((doc)=>{ setCategoria(doc.data().nomeCategoria)});

      await listRef.doc(id).collection('produtos').orderBy('created', 'desc').limit(15)
      .get()
      .then((snapshot) => {
        
        updateState(snapshot)
        
        // let lista = [];
        // snapshot.forEach((doc) => {
        //   lista.push({
        //     nomeProduto: doc.data().nomeProduto,
        //     categoria: doc.data().categoria,            
        //     createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy' ),
        //   })

          
       //   setProdutos(lista);

          // if(id){
          //   loadId(lista)
          // }
    //    })

      })
      .catch((err) => {
        console.log('Erro ao buscar: ', err);
        setLoadingMore(false);
      })

      

   
      setLoading(false);
    }

    loadCategorias();

    return () => {

    }

  }, [id]);

  // async function loadId(lista){

  //   console.log(id);
  //   await firebase.firestore().collection('chamados').doc(id)
  //   .get()
  //   .then((snapshot) => {


  //   })
  //   .catch((err) => {
  //     console.log('Erro no ID passado: ', err);
  //     //setIdCustomer(false);
  //   })
  // }
  

  async function updateState(snapshot){
   const isCollectionEmpty = snapshot.size === 0;

   if(!isCollectionEmpty){
     let lista = [];

     snapshot.forEach((doc)=>{
       lista.push({
        idProduct: doc.id,
        categoria: doc.data().categoria,            
        //createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy' ),
        nomeProduto: doc.data().nomeProduto,
        createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy' ),
        imageUrl: doc.data().imageUrl,
        valor: doc.data().valor
         
       })
     })

     const lastDoc = snapshot.docs[snapshot.docs.length - 1];//pegando o ultimo documento buscado

    setProdutos(produtos => [...produtos, ...lista]);
     setLastDocs(lastDoc);

    }else{
      setIsEmpty(true);
    }

    setLoadingMore(false);
  }

  async function handleMore(){
    setLoadingMore(true);
    await listRef.doc(id).collection('produtos').startAfter(lastDocs).limit(15)
    .get()
    .then((snapshot)=>{
      updateState(snapshot)
    })

  }

  function togglePostModal(item) {
    setShowPostModal(!showPostModal) // trocando de true para false
    setDetail(item);

  }


  if(loading){
    return(
      <div>
        <Header/>

        <div className="content">
          <Title name={`${categoria}`}>
              <CgListTree size={25}/>
          </Title>

          <div className="container dashboard">
            <span>Buscando categorias e produtos...</span>
          </div>

        </div>
      </div>
    )
  }


 return (
   <div>
     <Header/>
     <div className="content">
     <Title name={`${categoria}`}>
          <CgListTree size={25}/>
       </Title>

      {produtos.length === 0 ? (

        <div className="container dashboard">
        <span>Nenhuma categoria ou produto registrado...</span>

          <Link to="/newcategory" className="new">
            <FiPlus size={25} color="#fff"/>
            Nova Categoria
          </Link>

          <Link to="/newproduct" className="new">
            <FiPlus size={25} color="#fff"/>
            Novo produto
          </Link>

        </div>

      ) : (
        <>
          <Link to="/newcategory" className="new">
            <FiPlus size={25} color="#fff"/>
            Nova Categoria
          </Link>

          <Link to="/newproduct" className="new">
            <FiPlus size={25} color="#fff"/>
            Novo produto
          </Link>

          <table>
            <thead>
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">Foto</th>
                <th scope="col">Valor</th>
                <th scope="col">Ultima autalização em</th>
                <th scope="col">#</th>                
              </tr>
            </thead>
            <tbody>
              {produtos.map((item, index)=>{
                return (
                  <tr key={index}>
                    <td data-label="Categoria">{item.nomeProduto}</td>
                    <td data-label="Foto" ><img className="imageUrl" src={item.imageUrl} alt="Foto produto"/> </td>
                    <td data-label="Total">{parseFloat(item.valor).toFixed(2)}</td>
                    <td data-label="Atualizacao">{item.createdFormated}</td>
                    <td data-label="#">
                      <Link className="action" style={{backgroundColor: '#f6a935'}} to={`/editproduct/${id}/${item.idProduct}`} >
                        <FiEdit2 color="#fff" size={17}/>
                      </Link>
                    </td>
                  </tr>
                )
              })} 

            
            </tbody>
          </table>


          {loadingMore && <h3 style={{textAlign: 'center', marginTop: 15}} >Buscando dados...</h3>}
          {!loadingMore && !isEmpty && <button className="btn-more" onClick={handleMore}>Buscar mais</button>}

        </>
      )}
   
     </div>
     
      {showPostModal && (
        <Modal
          conteudo={detail}
          close={togglePostModal}
        />
      )}

   </div>
 );
}