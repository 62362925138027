import React, { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useNavigate, useParams } from 'react-router-dom';
import exampleImage from '../../assets/product.png';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify'

import '../New/new.css';
import './newproduct.css';
import { FiPlus } from 'react-icons/fi'
import { setWeekYear } from 'date-fns';

export default function NewProduct() {

  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [loadCategories, setLoadCategories] = useState(true);
  const [categories, setCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState(0);

  const [nomeProduto, setNomeProduto] = useState('');
  const [valor, setValor] = useState(0);
  const [complemento, setComplemento] = useState('');

  const [productUrl, setProductUrl] = useState(exampleImage);
  const [imageProduct, setImageProduct] = useState(null);
  const [statusProduct, setStatusProduct] = useState(true);

  let uid = user.uid;

  function handleFile(e) {

    if (e.target.files[0]) {
      const image = e.target.files[0];

      if (image.type === 'image/jpeg' || image.type === 'image/png') {

        setImageProduct(image);
        setProductUrl(URL.createObjectURL(e.target.files[0]))

      } else {
        alert('Envie uma imagem do tipo PNG ou JPEG');
        setImageProduct(null);
        return null;
      }
    }


  }

  useEffect(() => {
    async function loadCategories() {
      await firebase.firestore().collection('pdvUsers')
        .doc(uid)
        .collection('categorias')
        .get()
        .then((snapshot) => {

          let lista = [];

          snapshot.forEach((doc) => {
            lista.push({
              id: doc.id,
              nomeCategoria: doc.data().nomeCategoria

            })
          })

          if (lista.length === 0) {
            console.log('Nenhuma categoria encontrada.');
            setCategories([{ id: '1', nomeCategoria: 'FREELA' }]);
            setCategories(false);

            return;
          }

          setCategories(lista);
          setLoadCategories(false);

        })
        .catch((error) => {
          console.log('Aconteceu algum erro!', error);
          setCategories(false);
          setCategories([{ id: '1', nomeCategoria: '' }]);
        })
    }

    loadCategories();

  }, [id]);


  async function handleRegister(e) {
    e.preventDefault();
    if (imageProduct === null) {
      toast.error('Escolha uma foto para o produto.');
      return;
    } else if (nomeProduto === '' || valor === '' || categories[categorySelected].id === '') {
      toast.error('Preencha os campos obrigatorios! (nome do produto, valor do produto e categoria).');
      return;
    } else if (valor <= 0) {
      toast.error('O valor do produto não pode ser negativo.');
      return;
    }

    const currentUid = user.uid;
    console.log(currentUid)
    console.log(categories[categorySelected].id)
    console.log(imageProduct.name)

    await firebase.storage()
      .ref(`pdvUsers/images/${currentUid}/categorias/${categories[categorySelected].id}/produtos/${imageProduct.name}`)
      .put(imageProduct)
      .then(async () => {
        console.log('Foto enviada com sucesso!');

        await firebase.storage().ref(`pdvUsers/images/${currentUid}/categorias/${categories[categorySelected].id}/produtos`)
          .child(imageProduct.name).getDownloadURL()
          .then(async (url) => {
            let urlFoto = url;


            //products = rota que leva até as categorias de produtos do pdv
            let productsRef = firebase.firestore().collection('pdvUsers').doc(uid).collection('categorias');
            await productsRef.doc(categories[categorySelected].id)//aqui encontra a categoria pelo id/chave de acesso dela
              .collection('produtos')//cria/navega a/para aba produtos
              .add({//cria uma chave de acesso para o produto, depois disso salva os dados do mesmo
                created: new Date(),
                edited: new Date(),
                imageUrl: urlFoto,
                categoria: categories[categorySelected].nomeCategoria,
                categoriaId: categories[categorySelected].id,
                nomeProduto: nomeProduto,
                valor: valor,
                statusProduct,
                complemento: complemento,
                userId: user.uid
              })
              .then(() => {
                toast.success('Produto criado com sucesso!');
                setNomeProduto('');
                setValor(0);
                setComplemento('');
                navigate(`/categories/${categories[categorySelected].id}`);
                setCategorySelected(0);

              })
              .catch((error) => {
                toast.error('Ops erro ao registrar, tente mais tarde.')
                console.log('erro fi', error);
              })
          })
      }).catch((error) => {
        toast.error('Ops erro!.')
        console.log('erro: ', error);
      })

  }

  function handleStatusProdutct(status) {
    if(status === 'true'){
      setStatusProduct(true);
    }else if(status === 'false'){
      setStatusProduct(false);
    }else(alert(status))
  }

  //chamado quando troca de categoria
  function handleChangeCategories(e) {
    // console.log('index selecionado: ', e.target.value);
    //console.log('categoria selecionada ', categories[e.target.value]);
    setCategorySelected(e.target.value);
    //   setKeyCategory(categories[e.target.value]);// aqui salva a chave de acesso da categoria selecionada
  }

  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Novo produto">
          <FiPlus size={25} />
        </Title>


        <div className="container">

          <form className="form-profile" onSubmit={handleRegister}>
            <label>Categorias</label>

            {loadCategories ? (
              <input type="text" disabled={true} value="Carregando categorias..." />
            ) : (

              <select style={{  fontSize: '18px' }} value={categorySelected} onChange={handleChangeCategories}>
                {categories.map((item, index) => {

                  return (
                    <option key={item.id} value={index}>
                      {item.nomeCategoria}
                    </option>
                  )
                })}
              </select>
            )}


            <label>Nome do produto</label>
            <input type="text" placeholder="Nome resumido do produto" value={nomeProduto} onChange={(e) => setNomeProduto(e.target.value)} />

            <label>Valor do produto</label>
            <input type="number" value={valor} onChange={(e) => setValor(e.target.value)} />

            <br /><span>Clique para alterar o status do produto:</span>
            <select style={{ padding: '0', fontSize: '18px' }} value={statusProduct} onChange={(e) => handleStatusProdutct(e.target.value)}>
              <option>Selecione um status...</option>
              <option value={true}>Ativar produto</option>
              <option value={false}>Desativar produto</option>
            </select>



            <label>Complemento</label>
            <textarea
              type="text"
              placeholder="Descreva o produto (opcional)."
              value={complemento}
              onChange={(e) => setComplemento(e.target.value)}
            />
            <label>Foto do produto</label>
            <input class="image-product" type="file" accept="image/*" onChange={handleFile} /><br />
            {productUrl === null ?
              <img src={exampleImage} width="150" height="150" alt="Foto logo" />
              :
              <img src={productUrl} width="150" height="150" alt="Foto logo" />
            }

            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>

    </div>
  );
}