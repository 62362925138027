import config from "../../config/index.json"
// Função para listar todas as mesas
export async function listTables(user) {
    let url = `${config.urlServer}/pdv/tables/list`;
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      uid: user.uid,
      tokenfirebase: user?.token_firebase,
      tokenbackend: user?.token_backend,
      email: user.email,
      restaurantid: user.restaurant_id,
      userpdvid: user.user_pdv_id
    };
  
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headers
      });
  
      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        const errorData = await response.json();
        throw new Error(`Failed to list tables: ${errorData.msg}`);
      }
    } catch (error) {
      console.error("Error while listing tables:", error);
      throw error;
    }
  }
  