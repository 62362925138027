import { Routes, Route } from 'react-router-dom'

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';

import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import Customers from '../pages/Customers'
import New from '../pages/New';

import Requests from '../pages/Requests';
import HistoryRequest from '../pages/HistoryRequest';
import HistoryRequestYear from '../pages/HistoryRequest/HistoryRequestYear';
import HistoryRequestMonth from '../pages/HistoryRequest/HistoryRequestMonth';
import Categories from '../pages/Categories';
import Products from '../pages/Products';
import NewCategory from '../pages/NewCategory';
import NewProduct from '../pages/NewProduct';
import NewTable from '../pages/NewTable';
import EditProduct from '../pages/EditProduct';
import LocalFlow from '../pages/LocalFlow';
import Testing from '../pages/Testing';
import Contabil from '../pages/Contabil';
import CommandOpen from '../pages/CommandOpen';
import CommandOrders from '../pages/CommandOrders';



import Private from './Private'
function RoutesApp(){
  return(
    <Routes>
      <Route  path="/" element={<SignIn/>}/>
      <Route  path="/register" element={<SignUp/>} />


      <Route  path="/requests" element={<Private> <Requests/></Private>} /> {/*PEDIDOS RECEBIDOS*/}
      <Route  path="/command-open" element={<Private> <CommandOpen/></Private>} />{/*PEDIDOS RECEBIDOS EM COMANDA ABERTA*/}
      <Route  path="/command-open/:idCommand" element={<Private> <CommandOrders/></Private>} />{/*DETALHES DE UMA COMANDA ABERTA*/}
      <Route  path="/historyrequest" element={<Private> <HistoryRequest/></Private>} />{/*DESABILITADO*/}
      <Route  path="/historyrequestyear" element={<Private> <HistoryRequestYear/></Private>} />{/*HISTORICO CLASSIFICADO POR ANO*/}
      <Route  path="/historyrequestyear/:idYear/:idMonth" element={<Private> <HistoryRequestMonth/></Private>} />{/*HISTORICO DENTRO DE UM MES E ANO SELECIONADO*/}
      
      <Route  path="/categories" element={<Private> <Categories/></Private>} />{/* //       */}
      <Route  path="/categories/:id" element={<Private> <Products/></Private>} />{/* //       */}
      <Route  path="/newcategory" element={<Private> <NewCategory/></Private>} />{/* //       */}
      <Route  path="/newproduct" element={<Private> <NewProduct/></Private>} />{/* //       */}
      <Route  path="/newtable" element={<Private> <NewTable/></Private>} />{/* //       */}
      <Route  path="/editproduct/:idCategory/:idProduct" element={<Private> <EditProduct/></Private>} />{/* //       */}
      <Route  path="/localflow" element={<Private> <LocalFlow/></Private>} />{/* //       */}
      <Route  path="/contabil" element={<Private> <Contabil/></Private>} />{/* //       */}

      

      <Route path="/dashboard" element={<Private> <Dashboard/></Private>} />{/* //       */}
      <Route path="/profile" element={<Private> <Profile/></Private>} />{/* //       */}
      <Route path="/customers" element={<Private> <Customers/></Private>} />{/* //       */}
      <Route path="/new" element={<Private> <New/></Private>} />{/* //       */}
      <Route path="/new/:id" element={<Private> <New/></Private>} />{/* //       */}

      <Route path="/testing" element={<Private> <Testing/></Private>} />{/* //       */}


    </Routes>
  )
}


export default RoutesApp;